import { useEffect, useState } from "react";
import { del, get } from "../../../utility/fetch";
import { usePatient } from "../../../contexts";
import AppointmentModal from "../../modals/Appointment";
import Discharge from "../../modals/Discharge";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import notification from "../../../utility/notification";
import DeleteConfirmationModal from "../../modals/DeleteConfirmation";

function Appointment({ data }) {
  const { patientId } = usePatient();
  const [combinedData, setCombinedData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewing, setViewing] = useState({});
  const [isModalOpenDel, setIsModalOpenDel] = useState(false);
  const [add, setAdd] = useState(false);
  const [nurses, setNurses] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const closeModalDel = () => { setIsModalOpenDel(false); }
  const [recordToDelete, setRecordToDelete] = useState(null);




  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const generatePageNumbers = () => {
    let pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, 4, totalPages];
      } else if (currentPage >= totalPages - 2) {
        pages = [1, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
      } else {
        pages = [1, currentPage - 1, currentPage, currentPage + 1, totalPages];
      }
    }
    return pages;

  };


  const handleDeleteConfirmation = (recordId) => {
    setRecordToDelete(recordId);
    setIsModalOpenDel(true);
  };

  const confirmDelete = () => {
    if (recordToDelete) {
      handleDelete(recordToDelete);
      setIsModalOpenDel(false);
    }
  };

  const handleDelete = (id) => {
    del(`/Appointment/Delete-appointment?Id=${id}`)
      .then(res => {
        console.log(res)
        if (res.message === "The appointment has been removed from the doctor schedule table") {
          notification({ message: 'Cancelled appointment successfully', type: "success" });
          fetchData(currentPage)
        } else {
          notification({ message: 'Failed to delete appointment', type: "error" });
        }
      })
      .catch(err => {
        notification({ message: 'Failed to delete appointment', type: "error" });
        console.log(err)
      })
  }


  const fetchData = async (currentPage) => {
    try {
      const response = await get(`/appointment/get-appointment-bypatientId/${patientId}?pageIndex=${currentPage}&pageSize=10`);
      setCombinedData(response?.data)
      setTotalPages(response?.pageCount);

    } catch (e) {
      console.log(e);
    }
  };

  const getNurses = async () => {
    try {
      let res = await get(`/patients/Allnurse/${sessionStorage.getItem("clinicId")}?pageIndex=1&pageSize=300`);
      console.log(res);
      let tempNurses = res?.data?.map((nurse) => {
        return { name: nurse?.username, value: parseFloat(nurse?.employeeId) };
      });

      tempNurses?.unshift({ name: "Select Nurse", value: "" });
      setNurses(tempNurses);
    } catch (error) {
      console.error("Error fetching nurses:", error);
    }
  };

  const getDoctors = async () => {
    try {
      let res = await get(`/patients/AllDoctor/${sessionStorage.getItem("clinicId")}?pageIndex=1&pageSize=300`);
      console.log(res);
      let tempDoc = res?.data?.map((doc) => {
        return { name: doc?.username, value: parseFloat(doc?.employeeId) };
      });

      tempDoc?.unshift({ name: "Select Doctor", value: "" });
      setDoctors(tempDoc);
    } catch (error) {
      console.error("Error fetching doctors:", error);
    }
  };

  useEffect(() => {
    getNurses();
    getDoctors();
  }, [])


  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const closeModal = () => {
    setIsModalOpen(false);
    setAdd(false)
  };

  const selectRecord = (record) => () => {
    setViewing(record);
    setIsModalOpen(true);
  };

  const handleEdit = (record) => {
    setViewing(record);
    setAdd(true);
  }


  console.log(combinedData)

  return (
    <div className="w-100">
      <div className="w-100 none-flex-item m-t-40">
        <table className="bordered-table">
          <thead className="border-top-none">
            <tr className="border-top-none">
              <th className="center-text">Date</th>
              <th className="center-text">Time</th>
              <th className="center-text">Additional Notes</th>
              <th className="center-text">Vital Nurse</th>
              <th className="center-text">Assigned Doctor</th>
              <th className="center-text">Status</th>
              {/* <th className="center-text">Discharge/Visit Summary</th> */}
              <th className="center-text">Action</th>
              <th style={{ borderLeft: 'none !important' }} className="center-text"></th>
            </tr>
          </thead>
          <tbody className="white-bg view-det-pane">
            {combinedData.map((row) => (
              <tr key={row?.id}>
                <td>{new Date(row?.appointDate).toLocaleDateString()}</td>
                <td>
                  {(() => {
                    const [hours, minutes] = row?.appointTime.split(':');
                    const date = new Date();
                    date.setHours(hours, minutes);
                    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                  })()}
                </td>
                <td style={{ maxWidth: '650px', whiteSpace: 'wrap', textAlign: 'left', paddingLeft: '12px' }}>{row?.description}</td>
                <td>{row?.nurse}</td>
                <td>{row?.doctor}</td>
                <td>{row?.tracking}</td>
                {/* <td>{row?.doctor}</td> */}
                <td>
                  <button className="submit-btn" onClick={selectRecord(row)} style={{ backgroundColor: '#F13529', cursor: 'pointer', border: 'none' }}>Discharge</button>
                </td>
                <td>
                  <div className="flex space-between">
                    <div>
                      <RiEdit2Fill size={20} onClick={() => handleEdit(row?.id)} style={{ color: 'green', cursor: 'pointer' }} />
                    </div>
                    <div>
                      <RiDeleteBin2Fill size={20} onClick={() => handleDeleteConfirmation(row.id)} style={{ color: 'red', cursor: 'pointer' }} />
                    </div>
                  </div>
                </td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
      <div>
        <div className="pagination flex space-between  col-4 m-t-20">
          <div className="flex gap-8">
            <div className="bold-text">Page</div> <div className=" m-r-20">{currentPage}/{totalPages}</div>
          </div>
          <div className="flex gap-8">
            <button
              className={`pagination-btn ${currentPage === 1 ? 'disabled' : ''}`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {"Previous"}
            </button>

            {generatePageNumbers().map((page, index) => (
              <button
                key={`page-${index}`}
                className={`pagination-btn ${currentPage === page ? 'bg-green text-white' : ''}`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            ))}

            <button
              className={`pagination-btn ${currentPage === totalPages ? 'disabled' : ''}`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {"Next"}
            </button>
          </div>
        </div>
      </div>

      {
        isModalOpen && (
          <Discharge
            closeModal={closeModal}
            appointment={viewing}
            fetch={fetchData}
            currentPage={currentPage}
          />
        )
      }
      {
        add && (
          <AppointmentModal
            closeModal={closeModal}
            appointmentId={viewing}
            type={'appointment'}
            fetch={fetchData}
            currentPage={currentPage}
          />
        )
      }
      {
        isModalOpenDel && (
          <DeleteConfirmationModal
            closeModal={closeModalDel}
            confirmDelete={confirmDelete}
            equipment={'patient'}
          />
        )
      }

    </div>
  );
}
export default Appointment;
