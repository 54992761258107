import React, { useEffect, useState } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import InputField from '../UI/InputField';
import TextArea from '../UI/TextArea';
import { formatDate } from "../../utility/general";
import TagInputs from '../layouts/TagInputs';
import { get, post } from '../../utility/fetch';
import { usePatient } from '../../contexts';
import notification from '../../utility/notification';

function AppointmentModal({ closeModal, appointmentId, type, fetch, currentPage }) {
    const [nurses, setNurses] = useState({});
    const [nurse, setNurse] = useState({});
    const [doctor, setDoctor] = useState({});
    const [doctors, setDoctors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({});
    const { patientId, patientInfo, setPatientId, setPatientInfo } = usePatient();

    useEffect(() => {
        getNurses();
        getDoctors();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event?.target;

        setPayload(prevPayload => ({
            ...prevPayload,
            [name]: value
        }));
    };


    const handleSelectChange = (value, name) => {
        if (name === 'nurse') {
            setNurse(value)
            setPayload(prevPayload => ({ ...prevPayload, nurseEmployeeId: value?.value }));
        } else {
            setDoctor(value)
            setPayload(prevPayload => ({ ...prevPayload, doctorEmployeeId: value?.value }))
        }
    };


    const getNurses = async () => {
        try {
            let res = await get(`/patients/Allnurse/${sessionStorage.getItem("clinicId")}?pageIndex=1&pageSize=300`);
            console.log(res);
            let tempNurses = res?.data
                ?.filter((nurse) => nurse?.username)
                .map((nurse) => {
                    return { label: nurse?.username, value: parseFloat(nurse?.employeeId) };
                });

            tempNurses?.unshift({ name: "Select Nurse", value: "" });
            setNurses(tempNurses);
        } catch (error) {
            console.error("Error fetching nurses:", error);
        }
    };

    const getDoctors = async () => {
        try {
            let res = await get(`/patients/AllDoctor/${sessionStorage?.getItem("clinicId")}?pageIndex=1&pageSize=300`);
            console.log(res);
            let tempDoc = res?.data
                ?.filter((doc) => doc?.username)
                .map((doc) => {
                    return { label: doc?.username, value: parseFloat(doc?.employeeId) };
                });

            tempDoc?.unshift({ label: "Select Doctor", value: "" });
            setDoctors(tempDoc);
        } catch (error) {
            console.error("Error fetching doctors:", error);
        }
    };
    const fieldLabels = {
        appointDate: "Appointment Date",
        appointTime: "Appointment Time",
        doctorEmployeeId: "Doctor to meet",
        description: "Additional Details",
    };

    console.log(patientId)

    const addAppointmemt = async () => {
        if (!validatePayload()) {
            return;
        }

        const admitted = type === 'admission' ? true : false;

        const formattedValue = payload?.appointDate.replace(/-/g, '/')

        const url = appointmentId
            ? `/Appointment/update-appointment`
            : `/Appointment/create-appointment`;

        setLoading(true);
        try {
            let res = await post(url, {
                ...payload,
                patientId: patientId,
                appointDate: formattedValue,
                isAdmitted: admitted,
                ...(appointmentId ? { id: appointmentId } : {})
            });

            if (res.message === "successfully created appointment" || res.message === "successfully updated appointment" || res.message === "successfully admitted patient") {
                notification({ message: res?.message, type: "success" });
                setPayload({
                    appointDate: "",
                    appointTime: "",
                    description: "",
                    doctorEmployeeId: 0,
                });
                setDoctor({});
                setNurse({});
                fetch(currentPage)
                setLoading(false);
                closeModal();
            } else if (res.StatusCode === 401) {
                notification({ message: "Unauthorized Session", type: "error" });
                setLoading(false);
            } else if (res.StatusCode === 500) {
                notification({ message: "Internal Server Error", type: "error" });
                setLoading(false);
            } else {
                let errorMessage = `Failed to ${appointmentId ? 'update' : 'create'} ${type}`;

                if (res && res.errors) {
                    const errors = res.errors;
                    console.log(errors);

                    const customFieldNames = {
                        appointDate: "Appointment Date",
                        appointTime: "Appointment Time",
                        doctorEmployeeId: "Doctor to meet",
                        description: "Additional Details",
                    };

                    const missingFields = Object.keys(errors).filter((field) => {
                        return errors[field].some((errorMsg) => /is required/i.test(errorMsg));
                    });

                    console.log(missingFields);

                    if (missingFields.length > 0) {
                        const formattedFields = missingFields.map((field) => {
                            if (customFieldNames[field]) {
                                return customFieldNames[field];
                            }
                            return field.replace(/([a-z])([A-Z])/g, "$1 $2");
                        });

                        errorMessage = `The following fields are required: ${formattedFields.join(", ")}`;
                    }
                }

                notification({ message: errorMessage, type: "error" });
                setLoading(false);
            }
        } catch (error) {
            notification({ message: `Failed to ${appointmentId ? 'update' : 'create'} ${type}`, type: "error" });
            setLoading(false);
        }
    };


    const validatePayload = () => {
        let validationErrors = {};
        let missingFields = [];

        Object.keys(fieldLabels).forEach((field) => {
            if (!payload[field]) {
                validationErrors[field] = `${fieldLabels[field]} is required`;
                missingFields.push(fieldLabels[field]);
            }
        });

        if (missingFields.length > 0) {
            const errorMessage = `The following fields are required: ${missingFields.join(", ")}`;
            notification({ message: errorMessage, type: "error" });
        }

        return Object.keys(validationErrors).length === 0;
    };

    return (
        <div className='overlay'>
            <RiCloseFill className='close-btn pointer' onClick={closeModal} />
            <div className="modal-contents">
                <div className="flex ">
                    <div className="flex space-between flex-v-center m-t-20 m-l-20 col-4">
                        {
                            type === 'admission' ? (
                                <h4>Admit Patient</h4>
                            ) : (
                                <>
                                    {appointmentId ? (
                                        <h4>Reschedule Appointment</h4>
                                    ) : (
                                        <h4>Patient's Appointment</h4>
                                    )}
                                </>
                            )
                        }

                    </div>
                </div>
                <div className="p-20">
                    <div className='flex space-between flex-v-center'>
                        <div className='m-r-10'>
                            <TagInputs label={type == 'admission' ? 'Admission Date' : "Appointment Date"} value={payload?.appointDate} dateRestriction={type == 'admission' ? 'past' : "future"} onChange={handleChange} type='date' name="appointDate" />
                        </div>
                        <div>
                            <TagInputs label={type == 'admission' ? 'Admission Time' : "Appointment Time"} value={payload?.appointTime} onChange={handleChange} type='time' name="appointTime" />
                        </div>
                    </div>
                    <div className='w-100'>
                        <TagInputs label="Doctor to meet" name="doctorEmployeeId" value={doctor} onChange={(e) => handleSelectChange(e, 'doctor')} type='R-select' options={doctors} />
                    </div>
                    <div className='w-100'>
                        <TagInputs label="Assign Nurse" name="doctorEmployeeId" value={nurse} onChange={(e) => handleSelectChange(e, 'nurse')} type='R-select' options={nurses} />
                    </div>


                    <div>
                        <TagInputs label="Additional Details" value={payload?.description} name="description" onChange={handleChange} type='textArea' />
                    </div>

                    <button className="submit-btn m-t-20 w-100" onClick={addAppointmemt} disabled={loading}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default AppointmentModal;